@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,300;1,400&display=swap");
@import "variables.scss";
* {
	margin: 0;
	padding: 0;
}
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// background: linear-gradient(145deg, #03134e 20%, #001e88 80%);
	background: #09053a;
	background-attachment: fixed;
	color: $text;
	font-size: 18px;
	font-family: "Source Sans Pro", sans-serif;
	font-family: "Fira Code", code;
	scroll-behavior: smooth;
	overflow: auto;
	a:visited,
	a {
		color: $text;
		text-decoration: none;
	}

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px white;
		border-radius: 20px;
	}
	&::-webkit-scrollbar-thumb {
		background: $text;
		border-radius: 10px;
		height: 10%;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: $accent;
	}
	&::-webkit-scrollbar-track:hover {
		box-shadow: inset 0 0 10px white;
	}
}
#root {
	min-height: 100vh;
	position: relative;
}
.sections {
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.3s ease-in-out;
}
section {
	width: 75%;
	padding: 6rem 0rem;

	h1,
	h2,
	h3 {
		font-family: "Source Sans Pro", sans-serif;
		color: $accent;
	}
	h1 {
		font-size: 4rem;
		margin: 1rem 0;
	}
	h2 {
		font-size: 2rem;
		font-weight: 600;
		margin-bottom: 3rem;
		text-align: center;
	}
	h3 {
		font-size: 1.35rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}
	p {
		margin: 1rem 0rem;
	}
}

@media (max-width: 500px) {
	section {
		width: 100%;
		padding: 4rem 0rem;
		h1 {
			font-size: 3rem;
		}
		h2 {
			font-size: 1.75rem;
		}
		h3 {
			font-size: 1.3rem;
		}
		p {
			font-size: 1rem;
		}
	}
	.sections {
		padding: 0rem 2rem;
	}
}
