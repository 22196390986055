@import "variables.scss";

#Landing {
	min-height: calc(100vh - 16rem);
	display: flex;
	flex-direction: column;
	position: relative;
	h1 {
		span {
			color: $text-light;
		}
	}
	h2 {
		color: $text;
		text-align: left;
	}
	#cta {
		margin-top: 3rem;
		justify-self: flex-end;
	}
	.link-button {
		color: $accent;
		display: inline-block;
		padding: 0.6rem 1.4rem;
		margin-right: 1rem;
		border: 2px $accent solid;
		border-radius: 0.3rem;
		transition: all 0.2s ease-in-out;
		&:hover {
			color: $accent-sec;
			background-color: rgba(0, 255, 162, 0.233);
		}
	}
	#chevron {
		margin-top: auto;
		justify-self: flex-end;
		display: flex;
		flex-direction: column;
		align-items: center;

		font-size: 2.5rem;
		color: $accent;

		svg {
			animation: chevron 3s infinite ease-in alternate;
			margin-top: 0;
			border-radius: 1rem;
		}
		svg:last-child {
			font-size: 3rem;
			animation-delay: -0.3s;
			margin-top: -1.8rem;
		}
		@keyframes chevron {
			0% {
				transform: translate3d(0, 0, 0);
			}
			50% {
				transform: translate3d(0, 0.6rem, 0);
			}
			100% {
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

@media (max-width: 500px) {
	#Landing {
		min-height: calc(100vh - 12rem);
	}
}
