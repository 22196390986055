@import "variables.scss";

#Contact {
	min-height: 50vh;
	h3 {
		text-align: center;
		color: $text;
		font-size: 1.5rem;
	}
	.social {
		// Glass Morphism
		background: rgba(43, 36, 119, 0.3);
		-webkit-backdrop-filter: blur(15px);
		//
		padding: 1rem;
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		max-width: 50ch;
		margin: auto;
		transition: all 0.3s ease-in-out;
		a {
			width: 20ch;
			margin: 0.4rem 0;
			.item {
				display: inline-flex;
				align-items: center;
				svg {
					margin: 0 1rem;
					font-size: 2rem;
				}
			}
			&:hover {
				color: $accent;
			}
		}
	}
}
