@import "./variables.scss";
.Project {
    margin: auto;
    display: grid;
    grid-template-columns: 45% 55%;
    margin-top: 5rem;
    max-width: 100ch;

    .project-preview {
        border-radius: 0.75rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .image {
            max-width: 40ch;
            display: inline-flex;
            position: relative;
            border-radius: 0.75rem;
            box-shadow: 0.15rem 0.15rem 0 2px $accent-sec;
            transition: all 0.3s ease-in-out;

            img {
                transform: translate(-0.5rem, -0.5rem);
                transition: all 0.3s ease-in-out;
                border-radius: 0.75rem;
                max-width: 100%;
                filter: brightness(0.75);
            }
        }
    }

    &:hover {
        .project-preview .image {
            box-shadow: 0.1rem 0.1rem 0 1px $accent;

            img {
                filter: brightness(1);
                transform: translate(0, 0);
            }
        }
    }

    .project-info {
        margin: auto;
        margin-left: 0;
        margin-right: 0;
        min-height: 12rem;
        max-width: 60ch;
        border-radius: 0.75rem;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        padding: 0.75rem 1rem;
        padding-left: 2rem;

        display: flex;
        flex-direction: column;
        background: rgb(30, 31, 97);
        justify-content: space-evenly;
        h3 {
            margin-bottom: 0;
            font-family: "Fira Code", code;
            color: unset;
            font-weight: 500;
        }
        .title {
            text-align: center;
            font-size: 1.4rem;
            span {
                color: $accent;
            }
            margin-bottom: 1rem;
        }
        .description {
            position: relative;
            padding-right: 0.5rem;
            font-size: 1.1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .project-meta {
                font-size: 1rem;
                font-weight: 300;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                div {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 1rem;
                }
                span {
                    margin-right: 1rem;
                    color: $accent;
                    &.--link {
                        color: $text;
                        margin: 0;
                        i {
                            position: relative;

                            margin-right: 3ch;
                            svg {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &:hover {
                            color: $accent;
                        }
                    }
                }
            }
        }
    }
}

#Projects {
    :nth-child(odd) .Project {
        grid-template-columns: none;
        grid-template-columns: 55% 45%;
        .project-preview {
            order: 2;
            justify-content: flex-start;
        }
        .project-info {
            order: 1;
            margin-left: auto;
            padding: 0.75rem 1rem;
            padding-left: 2rem;

            border-radius: 0.75rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

@media (max-width: 900px) {
    #Projects {
        .Project {
            grid-template-columns: 100%;
            .project-preview {
                width: 90%;
                margin: auto;
                justify-content: center;
                .image {
                    box-shadow: 0 0 0 2px $accent-sec;
                    img {
                        transform: none;
                        filter: brightness(0.9);
                    }
                }
            }
            &:hover {
                .project-preview img {
                    filter: brightness(1);
                }
            }

            .project-info {
                border-radius: 0.75rem;
                padding: 0.75rem 1rem;
                margin-top: -3rem;
                z-index: 2;
            }
        }
        :nth-child(odd) .Project {
            grid-template-columns: 100%;
            .project-preview {
                order: 1;
                justify-content: center;
            }
            .project-info {
                order: 2;
                border-radius: 0.75rem;
                padding: 0.75rem 1rem;
            }
        }
    }
}
